import api from '@/api/index'

export default {
    allConcerns(params) {
        return api.get(`api/readAllUsers`, {params});
    },

    showConcerns(params){
        console.log(params.id)
        return api.get(`api/readUserById/${params.id}`);
    },

    deleteConcerns(params) {
        return api.delete(`api/deleteUserById/${params}`);
    },

    searchTicketNumber(params){
        return api.post('api/searchTicketNumber',params);
        // return api.post('api/createBranch',params);
    },
    getAgentName() {
        return api.get(`api/getAgentName`);
    },
}

// getBackJobDropDown(params){
//     return api.get(`/dropdown/getBackJobDropDown`)
// },