<template>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-title>
              Branches
            </v-card-title>
            <v-card-subtitle>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                width="100%"
                hide-details
              ></v-text-field>
            </v-card-subtitle>
            <v-data-table
            :headers="headers" 
            :items="filteredBranches" 
            >
            
            <template v-slot:item="{ item }">
              <tr @click="handleRowClick(item)" style="cursor: pointer;">
                <td>
                  <v-icon style="color: #eb8f34;">mdi-map-marker</v-icon>
                  <strong style="color: #eb8f34;">{{ item.name }}</strong><br>
                  <small>{{ item.address }}</small> <br>
                  <small>{{ item.distance != null ? (item.distance.value >= 1 ? Math.round(item.distance.value) : '< 1') : null }} {{ item.distance != null ? (item.distance.value >= 1 ? item.distance.unit : 'km') : null }}</small>
                </td>
                <td>
                  <v-icon style="color: red;">mdi-phone </v-icon> {{ item.contact }}
                </td>
              </tr>
            </template>

            </v-data-table>
            
          </v-card>
        </v-col>
  
        <v-col cols="12" sm="6">
          <v-card>
            <mapComp />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  

<script>
import mapComp from "@/views/Components/mapComp.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isExecuting: false,
      options:null,
			search: "",
      serverItemsLength: 10,
      branches: [],
      filteredBranches: [],
      headers: [
        {
          text: "Branch Details",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Contact",
          align: "start",
          sortable: false,
          value: "contact",
        },
      ],
    };
  },

  components: {
    mapComp,
  },

  mounted() {
    this.$store.commit("SET_USER_ROLE", "user");
    this.readBranchsDataTable()
  },

  methods: {
    handleRowClick(item){
      this.$store.commit('SET_CLICKED_MARKER',item.index)
      // console.log(item)
      // this.$store.commit('SET_CENTER',[item.latitude, item.longitude]);
      // this.$store.commit('SET_ZOOM',16);
    },
    
    async readBranchsDataTable() {
      if (this.isExecuting) {
        return; // Do not execute if the function is already running
      }
      try {
        this.isExecuting = true;
        await new Promise(resolve => setTimeout(resolve, 500)); // Add a delay of 1.5 seconds
        await this.$store.dispatch('readBranchsDataTableNoPaginator',this.options)
        .then(response => {
          this.branches = response.data
          this.serverItemsLength = response.total

            if ('geolocation' in navigator) {
              navigator.geolocation.getCurrentPosition(
              position => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                const markerLatLng = [latitude.toString(), longitude.toString()]
                this.$store.commit('SET_MARKER_LAT_LNG',markerLatLng);
                this.$store.commit('SET_CENTER',([latitude+0.001, longitude+0.001]));
                
                this.filterBranchOptions();
              },
              error => {
                this.branches = this.branches.map((item, index) => ({
                ...item,
                index,
              }));

                this.filteredBranches = this.branches;
                this.$store.commit('SET_FILTERED_BRANCH',this.branches)
                const arr = []
                this.filteredBranches.forEach(item => {
                  arr.push([item.latitude, item.longitude, {showPopup: true} ])
                });
                this.$store.commit('SET_MULTIPLE_MARKER_LAT_LNG',arr);
                console.error('Error occurred while obtaining location:', error);
              
              },
              {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
              }
              );
            } 
            else {
              this.branches = this.branches.map((item, index) => ({
                ...item,
                index,
              }));
              this.filteredBranches = this.branches;
              this.$store.commit('SET_FILTERED_BRANCH',this.branches)
              const arr = []
              this.filteredBranches.forEach(item => {
                arr.push([item.latitude, item.longitude, {showPopup: true} ])
              });
              this.$store.commit('SET_MULTIPLE_MARKER_LAT_LNG',arr);
              console.log('Geolocation is not supported by this browser.');
            }

        })

        this.isExecuting = false;
      } catch (error) {
        console.error('An error occurred:', error);
        this.isExecuting = false;
      }

    },
    
    filterBranchOptions() {
      if (!this.GET_MARKER_LAT_LNG) {
        return;
      }
      const userLatLng = this.GET_MARKER_LAT_LNG;
      const sortedOptions = [...this.branches];
      sortedOptions.sort((a, b) => {
        const latA = Number(a.latitude);
        const lonA = Number(a.longitude);
        const latB = Number(b.latitude);
        const lonB = Number(b.longitude);
        if (isNaN(latA) || isNaN(lonA) || isNaN(latB) || isNaN(lonB)) {
          // Skip invalid latitude/longitude values
          console.log('Invalid latitude/longitude:', a.latitude, a.longitude, b.latitude, b.longitude);
          return 0;
        }
        const distanceA = this.calculateDistance(userLatLng, [latA, lonA]);
        const distanceB = this.calculateDistance(userLatLng, [latB, lonB]);
        return distanceA.value - distanceB.value; // Compare by distance value
      });

      // Adding distance unit to each branch
      this.branches = sortedOptions.map((item, index) => ({
        ...item,
        index,
        distance: {
          value: this.calculateDistance(userLatLng, [item.latitude, item.longitude]).value,
          unit: 'km'
        }
      }));
      this.filteredBranches = this.branches;
      this.$store.commit('SET_FILTERED_BRANCH',this.filteredBranches)
      const arr = []
      this.filteredBranches.forEach(item => {
        arr.push([item.latitude, item.longitude, {showPopup: true} ])
      });
      this.$store.commit('SET_MULTIPLE_MARKER_LAT_LNG',arr);
    },

    calculateDistance(pointA, pointB) {
      // This example uses the Haversine formula
      const lat1 = pointA[0];
      const lon1 = pointA[1];
      const lat2 = pointB[0];
      const lon2 = pointB[1];
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = this.toRad(lat2 - lat1);
      const dLon = this.toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRad(lat1)) *
          Math.cos(this.toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return {
        value: distance,
        unit: 'km'
      };
    },

toRad(value) {
  return value * (Math.PI / 180);
},


  },

  computed: {
    ...mapGetters([
      "GET_USER_ROLE",
      "GET_FILTERED_BRANCH",
      "GET_MARKER_LAT_LNG",
      "GET_MULTIPLE_MARKER_LAT_LNG",
      "GET_ZOOM",
      "GET_CENTER",
    ]),
  },

  watch: {
    search:{
      handler(val) {
        const searchRegex = new RegExp(this.search, 'i'); // Case-insensitive search
        this.filteredBranches = this.branches.filter(
          (item) =>
            searchRegex.test(item.name) ||
            searchRegex.test(item.address) ||
            searchRegex.test(item.contact)
        );
        this.filteredBranches
      },
    },
    options: {
			handler(val) {
        this.readBranchsDataTable()
      },
		},
  },
};
</script>
