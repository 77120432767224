<template>
  <div class="pattern-background">
    <Header />
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-card class="elevation-10 mt-10 mb-12 orange lighten-5">
          <v-window>
            <v-window-item :value="1">
              <v-row>
                <v-col cols="12" md="6">
                  <v-card-text class="mt-3">
                    <h2 class="text-center ingcoGreetings">
                      Happy Day! <br />
                      We would like to hear from you
                    </h2>
                    <h5 class="text-center grey--text ingcoformreminder">
                      Please fill up the identification form <br />so that we
                      can assist you further.
                    </h5>
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="8">
                        <v-form ref="myForm" @submit.prevent>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <label class="txtName">Name</label>
                                <v-text-field
                                  v-model="form.name"
                                  outlined
                                  color="black"
                                  dense
                                  placeholder="Name"
                                  autocomplete="off"
                                  :rules="rules.required"
                                  ref="nameField"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <label class="txtAddress">Address</label>
                                <v-text-field
                                  v-model="form.address"
                                  outlined
                                  placeholder="Your Address"
                                  color="black"
                                  dense
                                  required
                                  counter="60"
                                  autocomplete="off"
                                  :rules="rules.required"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <label class="txtContact">Contact #</label>
                                <v-text-field
                                  v-model="form.contact"
                                  outlined
                                  color="black"
                                  dense
                                  placeholder="09123456789"
                                  autocomplete="off"
                                  maxlength="11"
                                  @keydown="limitCharacterCount"
                                  :rules="rules.contact"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <label class="txtEmail">Email</label>
                                <v-text-field
                                  v-model="form.email"
                                  outlined
                                  placeholder="yourname@email.com"
                                  color="black"
                                  dense
                                  autocomplete="off"
                                  type="email"
                                  :rules="rules.email"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <label class="txtConcerns"
                                  >Subject of Concern</label
                                >
                                <v-autocomplete
                                  placeholder="Ex. Refunds or Suggestions"
                                  v-model="form.subject_id"
                                  outlined
                                  dense
                                  :items="subjectsData"
                                  color="black"
                                  item-text="title"
                                  item-value="id"
                                >
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <label class="txtConcerns">Concerns</label>
                                <v-textarea
                                  placeholder="Your concerns"
                                  v-model="form.concerns"
                                  outlined
                                  color="black"
                                  name="input-7-4"
                                  :rules="rules.required"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-file-input
                            @change="handleFileChange()"
                            style="display: none"
                            ref="fileInput"
                            v-model="pictureData"
                            truncate-length="15"
                            outlined
                            multiple
                            accept="image/*"
                            color="orange lighten-2"
                          ></v-file-input>
                        </v-form>
                        <div class="d-flex justify-center mb-7">
                          <button @click="openModal" class="txtName">
                            <v-icon>mdi-image-multiple</v-icon>View Pictures
                          </button>
                          <v-spacer></v-spacer>
                          <button @click="openFileInput" class="txtName">
                            <v-icon>mdi-image-plus</v-icon>Upload Image
                          </button>
                          <v-spacer></v-spacer>
                          <button @click="showCamera" class="txtName">
                            <v-icon>mdi-camera</v-icon>Camera
                          </button>
                        </div>
                        <v-row>
                          <v-btn
                            class="mb-5"
                            color="orange lighten-2 black--text ingcoGreetings"
                            dark
                            block
                            tile
                            @click="submit"
                          >
                            Submit
                            <v-icon class="ml-2">mdi-email-fast</v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6" class="rounded-bl-xl metallicbg">
                  <div style="text-align: center; padding: 60px 0">
                    <v-col class="">
                      <div class="d-flex justify-center">
                        <v-img
                          lazy-src="@/assets/ingcoph_logo.jpg"
                          max-height="150"
                          max-width="300"
                          src="@/assets/ingcoph_logo.jpg"
                        ></v-img>
                      </div>
                      <div class="d-flex justify-center mt-15">
                        <template>
                          <div>
                            <v-dialog v-model="showModal" max-width="800px">
                              <v-card>
                                <v-card-title>
                                  <v-row>
                                    <v-col>
                                      <span class="headline">Pictures</span>
                                    </v-col>
                                    <v-col>
                                      <v-btn
                                        text
                                        icon
                                        color="gray"
                                        class="float-right"
                                        @click="closeModal"
                                      >
                                        <v-icon>mdi-close-circle</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-card-title>
                                <v-card-text>
                                  <v-row>
                                    <v-col> </v-col>
                                  </v-row>
                                  <v-row class="px-4">
                                    <v-col
                                      v-for="(item, index) in pictures"
                                      :key="index"
                                      cols="12"
                                      sm="6"
                                      md="4"
                                      lg="3"
                                    >
                                      <v-card @click="enlargeImage(item)">
                                        <v-row>
                                          <v-col class="d-flex justify-end">
                                            <v-btn
                                              icon
                                              small
                                              color="red"
                                              @click.stop="removeImage(index)"
                                            >
                                              <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                          </v-col>
                                          <v-col>
                                            <div class="image-container">
                                              <v-img
                                                contain
                                                :src="item.src"
                                                eager
                                                class="image"
                                              ></v-img>
                                            </div>
                                          </v-col>
                                          <v-card-text
                                            class="image-label text-center"
                                            >{{ item.name }}</v-card-text
                                          >
                                        </v-row>
                                        <v-divider />
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="showCameraDialog"
                              width="700px"
                              max-width="800px"
                              @click:outside="closeCameraDialog"
                            >
                              <v-card>
                                <v-card-title>
                                  <span class="headline">Camera</span>
                                  <v-btn
                                    text
                                    icon
                                    color="gray"
                                    class="float-right"
                                    @click="closeCameraDialog"
                                  >
                                    <v-icon>mdi-close-circle</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text>
                                  <v-row>
                                    <video
                                      ref="videoElement"
                                      autoplay
                                      style="transform: scaleX(-1)"
                                    ></video>
                                    <canvas
                                      ref="canvasElement"
                                      style="display: none"
                                    ></canvas>
                                  </v-row>
                                  <v-row>
                                    <v-btn @click="captureImage">Capture</v-btn>
                                  </v-row>
                                  <v-row> </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="showEnlarged"
                              width="700px"
                              max-width="800px"
                            >
                              <v-card>
                                <v-card-text>
                                  <v-img
                                    v-if="showEnlarged"
                                    contain
                                    :src="selectedImage"
                                    class="enlarged-image"
                                  ></v-img>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                          </div>
                        </template>
                      </div>
                    </v-col>
                    <v-card-text class="cardText">
                      <h1 class="text-center ingcophTitle">
                        INGCO Philippines
                      </h1>
                      <br />
                      <h4 class="text-center font-italic Yellow--text ingcotag">
                        Make The World In Your Hands
                      </h4>
                    </v-card-text>
                  </div>
                  <div class="d-flex justify-center">
                    <v-img
                      class=""
                      lazy-src="@/assets/ingcoserviceagent.png"
                      max-width="480"
                      src="@/assets/ingcoserviceagent.png"
                    >
                    </v-img>
                  </div>
                </v-col>
                <v-dialog v-model="customerticketDialog" max-width="750">
                  <v-card>
                    <v-toolbar
                      color="orange lighten-2"
                      dark
                      class="ingcoformreminder black--text text-h6"
                      >Your Ticket Number
                      <v-spacer></v-spacer>
                      <v-btn icon color="red" @click="btnCloseTicketDialog">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn></v-toolbar
                    >
                    <v-card-text>
                      <v-text-field
                        outlined
                        dense
                        readonly
                        ref="clone"
                        class="pa-12"
                        v-on:focus="$event.target.select()"
                        v-model="userTicketNum"
                      >
                        <template #append>
                          <v-btn
                            class="copy-button"
                            icon
                            @click="copyToClipboard"
                          >
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                      <small>Please Copy or Screenshot your Reference ID</small>
                    </v-card-text>
                    <v-divider></v-divider>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
    <Footer />
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { mapGetters } from "vuex";
const axios = require("axios");
// Imported Components or Dialogs from Other Folders Rooted @/views/
import Header from "@/views/Headers.vue";
import Footer from "@/views/Footers.vue";
import UserBranch from "@/views/Tables/UserBranchTable.vue";

export default {
  data: () => ({
    customerticketDialog: false,
    subjectsData: [],
    isCardActive: false,
    videoStream: null,
    capturedImage: null,
    isCommitCompleted: false,
    showEnlarged: false,
    showModal: false,
    selectedImage: null,
    showCameraDialog: false,
    contactLength: 11,
    userTicketNum: "",
    form: {
      name: null,
      contact: null,
      email: null,
      address: null,
      subject: null,
      subject_id: null,
      concerns: null,
    },
    pictureFile: [],
    pictureData: [],
    pictures: [],

    rules: {
      required: [(v) => !!v || "Field is required"],
      min: [(v) => v.length >= 8 || "Min 8 characters"],
      email: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      contact: [
        (v) =>
          (v !== null && v !== undefined && /^\d+$/.test(v)) ||
          "Only numeric values are allowed",
        (v) =>
          (v !== null && v !== undefined && v.length >= 11) ||
          "Min 11 characters",
      ],
    },
  }),

  methods: {
    async savePictures() {
      for (const pic of this.pictureData) {
        const reader = new FileReader();
        const loadPromise = new Promise((resolve) => {
          reader.addEventListener("load", () => {
            resolve(reader.result);
          });
        });
        reader.readAsDataURL(pic);
        const result = await loadPromise;
        this.pictures.push({
          src: result,
          name: pic.name,
        });
        this.pictureFile.push(pic);
      }
      this.isCommitCompleted = true;
    },
    handleFileChange() {
      if (!this.isCommitCompleted) {
        this.savePictures()
          .then(() => {
            this.resetInputValue();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    resetInputValue() {
      if (this.isCommitCompleted) {
        this.$refs.fileInput.reset();
        this.isCommitCompleted = false;
      }
    },
    submit() {
      if (this.$refs.myForm.validate()) {
        this.$swal
          .fire({
            title: "Submit Concern Request?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: "No, cancel!",
            confirmButtonText: "Yes, Submit it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const data = new FormData();
              const config = {
                headers: {
                  "content-type": "multipart/form-data",
                },
              };

              data.append("form", JSON.stringify(this.form));
              if (this.pictureFile.length > 0) {
                for (let i = 0; i < this.pictureFile.length; i++) {
                  data.append("files[]", this.pictureFile[i]);
                }
              }

              let payload = {
                params: data,
                config: config,
              };

              this.$store.dispatch("saveUser", payload).then((response) => {
                this.$swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "Operation completed successfully.",
                });
                this.userTicketNum = response.ticket_num;
                this.customerticketDialog = true;
                this.clear();
              });
            }
          });
      }
    },
    clear() {
      this.$refs.myForm.resetValidation();
      for (let key in this.form) {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = null;
        }
      }
      this.pictureFile = [];
      this.pictureData = [];
      this.pictures = [];
    },
    openFileInput() {
      this.$refs.fileInput.$el.querySelector("input").click();
    },
    limitCharacterCount(event) {
      if (this.form.contact && this.form.contact.length >= 11) {
        if (
          !(
            event.key === "Backspace" ||
            event.key === "Delete" ||
            event.key.includes("Arrow")
          )
        )
          event.preventDefault();
      }
    },

    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    enlargeImage(item) {
      this.selectedImage = item.src;
      this.showEnlarged = true;
    },
    removeImage(index) {
      this.pictures.splice(index, 1);
      this.pictureFile.splice(index, 1);
    },
    showCamera() {
      this.showCameraDialog = true;
      this.initializeCamera();
    },

    initializeCamera() {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          this.videoStream = stream;
          this.$refs.videoElement.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    },
    closeCameraDialog() {
      this.showCameraDialog = false;
      if (this.videoStream) {
        const tracks = this.videoStream.getTracks();
        tracks.forEach((track) => track.stop());
        this.videoStream = null;
      }
    },
    captureImage() {
      const videoElement = this.$refs.videoElement;
      const canvasElement = this.$refs.canvasElement;
      const context = canvasElement.getContext("2d");
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;
      context.scale(-1, 1); // Apply horizontal flip transformation
      context.drawImage(
        videoElement,
        0,
        0,
        -canvasElement.width,
        canvasElement.height
      ); // Use negative width for mirroring
      context.setTransform(1, 0, 0, 1, 0, 0); // Reset the transformation
      canvasElement.toBlob((blob) => {
        const file = new File([blob], "image.png", { type: "image/png" });
        this.pictureData.push(file);
        this.handleFileChange();
      }, "image/png");
    },
    fetchData() {
      this.$store
        .dispatch("getSubjects")
        .then((response) => {
          // The response data will be available here, you can use it as needed
          this.subjectsData = response.map((obj) => ({
            title: obj.title,
            description: obj.description,
            id: obj.id
          }));
          console.log(this.subjectsData)
        })
        .catch((error) => {
          // Handle the error here
          console.error("Error fetching data:", error);
        });
    },
    btnCloseTicketDialog() {
      this.customerticketDialog = false;
    },
    copyToClipboard() {
      this.$refs.clone.focus();
      document.execCommand("copy");
    },
  },
  computed: {
    ...mapGetters(["GET_SUBJECTS"]),
  },
  mounted() {
    this.fetchData();
  },
  props: {
    source: String,
  },

  components: {
    Footer,
    Header,
    UserBranch,
  },
};
</script>
<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}

.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}

.pattern-background {
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/carbon-fibre.png");
  background-repeat: repeat;
  background-color: rgb(76, 76, 76);
}

.image-container {
  height: 150px;
  /* Set the desired height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container .image {
  max-height: 100%;
  max-width: 100%;
}

.metallicbg {
  background-image: url("https://www.transparenttextures.com/patterns/carbon-fibre.png");
  background-repeat: repeat;
  background-color: rgb(77, 77, 77);
}

.cardText {
  color: #f29f13;
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("~@/assets/fonts/Gilroy-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "Evogria";
  src: url("~@/assets/fonts/Evogria.otf") format("opentype");
}

.ingcophTitle {
  font-family: "Gilroy-ExtraBold", sans-serif;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.ingcotag {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.ingcoGreetings {
  font-family: "Gilroy-ExtraBold", sans-serif;
}

.ingcoformreminder {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.txtName,
.txtContact,
.txtEmail,
.txtAddress,
.txtConcerns {
  font-family: "Evogria";
}
</style>
