import concerns from "@/api/modules/concerns";

export default {
  state: {
    allConcerns: [],
    concernsdialog: {
      visible: false,
      action: null,
    },
    showConcerns: {},
    deleteConcerns: "",
    // Ticket Status State
    searchTicketNumber: "",
    // Agent Name State
    getAgentName: '',
  },

  getters: {
    GET_ALL_CONCERNS: (state) => state.allConcerns,
    GET_CONCERNS_DIALOG_VISIBLE: (state) => state.concernsdialog.visible,
    GET_CONCERNS_DIALOG_ACTION: (state) => state.concernsdialog.action,
    GET_SHOW_CONCERNS: (state) => state.showConcerns,
    GET_DELETE_LABELS: (state) => state.deleteConcerns,
    // Ticket Status Getter
    GET_TICKET_NUM: (state) => state.searchTicketNumber,
    // Agenty name Getter
    GET_AGENT_ID: (state) => state.getAgentName,
  },

  mutations: {
    ALL_CONCERNS: (state, data) => {
      state.allConcerns = data;
    },
    CONCERNS_DIALOG_VISIBLE: (state, data) => {
      state.concernsdialog.visible = data;
    },
    CONCERNS_DIALOG_ACTION: (state, data) => {
      state.concernsdialog.action = data;
    },
    SHOW_CONCERNS: (state, data) => {
      state.showConcerns = data;
    },
    DELETE_CONCERN: (state, data) => {
      state.deleteConcerns = data;
    },
    // Ticket Status Setter
    SET_TICKET_NUM: (state, data) => {
      state.searchTicketNumber = data;
    },
    // Agent Name Setter
    SET_AGENT_NAME: (state, name) => {
      state.getAgentName = name;
    },
  },

  actions: {
    allConcerns({ commit }, payload) {
      return new Promise((resolve, reject) => {
        concerns
          .allConcerns(payload)
          .then((response) => {
            if (response.status == 200) {
              commit("ALL_CONCERNS", response.data);
              resolve(response.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    showConcerns({ commit }, payload) {
      return new Promise((resolve, reject) => {
        concerns
          .showConcerns(payload)
          .then((response) => {
            if (response.status == 200) {
              commit("SHOW_CONCERNS", response.data[0]);
              resolve(response.data[0]);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteConcerns({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        await concerns
          .deleteConcerns(payload)
          .then((response) => {
            commit("DELETE_CONCERN", payload);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async getAgentName({ commit }, payload) {
      return new Promise((resolve, reject) => {
        concerns
          .getAgentName(payload)
          .then((response) => {
  
            // Check if response.data array is not empty
            if(response.data.length > 0) {
              // Commit the 'name' attribute from the first object in the response data
              commit("SET_AGENT_NAME", response.data[0].name);
            } else {
              commit("SET_AGENT_NAME", 'No Agent Assigned Yet');
            }
            resolve(response);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },

    // searchTicketNumber({commit}, payload){
    //     return new Promise((resolve, reject)=> {
    //         concerns.searchTicketNumber(payload).then((response)=> {
    //             if(response.status == 200) {
    //                 commit('SET_TICKET_NUM', response.data)
    //                 resolve(response)
    //             }
    //         }).catch((error)=>{
    //             reject(error)
    //         })
    //     })
    // }

    searchTicketNumber({ commit }, payload) {
        const ticketNumPattern = /^C-TIX-\d{4}-\d{2}-\d{2}-\d{4}$/;
      
        if (!payload.ticket_num) {
          const error = new Error("Ticket number is required.");
          return Promise.reject(error);
        } else if (!ticketNumPattern.test(payload.ticket_num)) {
          const error = new Error("Invalid ticket number format.");
          return Promise.reject(error);
        }
      
        return new Promise((resolve, reject) => {
          concerns
            .searchTicketNumber(payload)
            .then((response) => {
              if (response.status === 200) {
                commit("SET_TICKET_NUM", response.data);
                resolve(response);
              } else if (response.status === 404) {
                const error = new Error("Ticket number not found.");
                reject(error);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
      

  },
  debug: true
};
