import api from '@/api/index'

export default {
    saveUser(params,config){
        return api.post('api/createUser',params,config.headers);
    },
    readUserDataTable(params) {
        return api.get(`api/readUserDataTable`, {params});
    },
    deleteUserById(params) {
        return api.delete(`api/deleteUserById`, {params});
    },
}