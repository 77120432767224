<template>
  <div class="pattern-background">
    <!-- Header -->
    <Header :hideButton="true" :hideUserBranchTableButton="false" />

    <!-- Main content -->
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-container class="mt-10">
            <mapUserBranch />
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!-- Footer -->
    <Footer />
  </div>
</template>

  

<script>
import mapUserBranch from "@/views/Components/mapUserBranch.vue";
import { mapGetters } from "vuex";
import Header from "@/views/Headers.vue";
import Footer from "@/views/Footers.vue";

export default {
  data() {
    return {
      selectedBranch: null,
      branches: [],
      filteredBranch: [],
      markerLatLng: [],
    };
  },

  mounted() {
    this.$store.commit("SET_USER_ROLE", "user");
  },

  components: {
    mapUserBranch,
    Footer,
    Header,
  },
};
</script>
