import subjects from '@/api/modules/subjects/index'

export default {
    state: {
        subjects: [],
    },
    getters: {
        GET_SUBJECTS:(state) => state.departments,
    },
    mutations: {
        SET_ALL_SUBJECTS:(state, data)=>{state.subjects = data}
    },
    actions: {
        getSubjects({commit}) {
            return new Promise((resolve, reject)=>{
                subjects.getSubjects().then((response)=>{
                    commit('SET_ALL_SUBJECTS', response.data)
                    resolve(response.data)
                }).catch((error)=> {
                    reject(error)
                })
            })
        }
    }
}