import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Swal from 'sweetalert2';
import Vue2Leaflet from 'vue2-leaflet';
import { LMap, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import '@/assets/fonts/Evogria.otf';
import '@/assets/fonts/Gilroy-ExtraBold.otf';

Vue.config.productionTip = false
Vue.prototype.$swal = Swal;
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);

Vue.filter('truncate', function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
