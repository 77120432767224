export default {
    state: {
        userRole: null,
        markerLatLng: [14.647788661717758, 120.98868385778356],
        MultipleMarkerLatLng: [],
        zoom: 7,
        center: [14.647788661717758, 120.98868385778356],
        clickedMarker: null,
    },
    
    getters: {
        GET_USER_ROLE:(state) => state.userRole,
        GET_MARKER_LAT_LNG:(state) => state.markerLatLng,
        GET_MULTIPLE_MARKER_LAT_LNG:(state) => state.MultipleMarkerLatLng,
        GET_ZOOM:(state) => state.zoom,
        GET_CENTER:(state) => state.center,
        GET_CLICKED_MARKER:(state) => state.clickedMarker,
    },

  mutations: {
    SET_USER_ROLE: (state, data) => {
      state.userRole = data;
    },
    SET_MARKER_LAT_LNG: (state, data) => {
      state.markerLatLng = data;
    },
    SET_MULTIPLE_MARKER_LAT_LNG: (state, data) => {
      state.MultipleMarkerLatLng = data;
    },
    SET_ZOOM: (state, data) => {
      state.zoom = data;
    },
    SET_CENTER: (state, data) => {
      state.center = data;
    },
    SET_CLICKED_MARKER: (state, data) => {
      state.clickedMarker = data;
    },
  },

  actions: {},
};
