<template>
  <div>
    <v-app-bar app color="rgb(242 159 19)" style="height: 85px">
      <v-toolbar-title>
        <div class="mt-16 mb-11">
          <v-img
            lazy-src="@/assets/ingcologo.png"
            max-width="220px"
            src="@/assets/ingcologo.png"
          ></v-img>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="mt-4">
        <v-btn
        v-if="!hideTicketStatusSearchButton"
        @click="openTicketStatus"
        class="mr-5 orange lighten-2"
          >Search Your Ticket
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn
          v-if="!hideButton"
          class="orange lighten-2 txtName mr-5"
          @click="openNewTab"
        >
          Service Centers Near Me
          <v-icon class="ml-3">mdi-map-marker-circle</v-icon>
        </v-btn>

        <v-btn
          v-if="!hideUserBranchTableButton"
          class="orange lighten-2 txtName"
          @click="openBackTab"
        >
          <img src="@/assets/ingcoserviceagent.png" alt="" width="30" />
          <v-icon class="ml-3">mdi-arrow-left</v-icon>
        </v-btn>

        <v-btn
          v-if="!showMapDialog"
          class="orange lighten-2 txtName"
          @click="openAdminDialog"
        >
          Add New INGCO Store
          <v-icon class="ml-3">mdi-map</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <div></div>
  </div>
</template>

<script>
// import UserBranch from "@/views/Tables/UserBranchTable.vue";
// import mapComp from '@/views/Components/mapComp.vue';
import { mapMutations } from "vuex";

export default {
  props: {
    hideButton: {
      type: Boolean,
      default: false,
    },
    hideUserBranchTableButton: {
      type: Boolean,
      default: true,
    },
    showMapDialog: {
      type: Boolean,
      default: true,
    },
    hideTicketStatusSearchButton : {
      type: Boolean,
      default: false,
    }
  },

  components: {
    mapCom: () => import("./Components/mapComp.vue"),
  },

  data() {
    return {};
  },

  methods: {
    openNewTab() {
      const url = "/UserBranchTable";
      window.open(url, "_blank");
    },
    openBackTab() {
      window.close();
    },
    openAdminDialog() {
      this.$emit("open-admin-dialog");
    },
    openTicketStatus() {
      const url = "/TicketStatus";
      window.open(url, "_blank");
    },
  },

  mounted() {
    // this.$refs.userBranch.initMap(); // Refresh the map when the modal is shown
  },
};
</script>

<style>
.pattern-background {
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/carbon-fibre.png");
  background-repeat: repeat;
  background-color: rgb(76, 76, 76);
}
</style>
