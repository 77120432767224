<template>
  <div>
    <div v-if="GET_USER_ROLE === 'user'">
      <l-map
        class="map-container"
        ref="map"
        style="height: 700px"
        :zoom="zoom"
        :center="center"
      >
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        ></l-tile-layer>
        <template v-if="GET_MULTIPLE_MARKER_LAT_LNG.length > 0">
          <l-marker
            v-if="GET_MARKER_LAT_LNG"
            :lat-lng="GET_MARKER_LAT_LNG"
            :icon="customIcon2"
            @click="home"
          ></l-marker>
          <l-circle
            v-if="GET_MARKER_LAT_LNG"
            :lat-lng="GET_MARKER_LAT_LNG"
            :radius="circleRadius"
            :fill="true"
            :fill-opacity="0.2"
            :color="'rgb(242 159 19)'"
            style="cursor: move"
          ></l-circle>
          <l-marker
            v-for="(item, index) in GET_MULTIPLE_MARKER_LAT_LNG"
            :key="index"
            :lat-lng="item"
            :icon="customIcon"
            @click="go(item)"
            ref="markers"
          >
          <!-- <l-popup :content="getTooltipContent(item) " ></l-popup> -->
          <l-popup  :content="getTooltipContent(item)"></l-popup>
          <!-- <l-tooltip :content="getTooltipContent(item) " ></l-tooltip> -->
          </l-marker>
        </template>
      </l-map>
      <v-container class="mt-5">
        <label for="">Adjust Radius</label>
        <v-row>
          <v-slider
            class="ml-5 mt-1"
            v-model="circleRadius"
            color="orange lighten-2"
            :min="100"
            :max="100000"
            :step="100"
          >
          </v-slider>
          <v-btn icon @click="home" class="mr-5">
            <img src="@/assets/marker-icon2.png" width="70" />
          </v-btn>
        </v-row>
      </v-container>
    </div>
    <v-lazy>
      <div>
        <l-map
          v-if="GET_USER_ROLE === 'admin'"
          class="map-container"
          ref="map"
          style="height: 500px"
          :zoom="zoom"
          :center="center"
          @click="moveMarker"
        >
          <l-marker
            v-if="GET_MARKER_LAT_LNG"
            :lat-lng="GET_MARKER_LAT_LNG"
            :icon="customIcon2"
          ></l-marker>
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          ></l-tile-layer>
          <template v-if="GET_MULTIPLE_MARKER_LAT_LNG.length > 0">
            <l-marker
              v-for="(item, index) in GET_MULTIPLE_MARKER_LAT_LNG"
              :key="index"
              :lat-lng="item"
              :icon="customIcon"
            ></l-marker>
          </template>
        </l-map>
      </div>
    </v-lazy>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon, LCircle,LTooltip, LPopup  } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from '@/assets/marker-icon.png';
import markerIcon2 from '@/assets/marker-icon2.png';
import markerIcon3 from '@/assets/ingcoserviceagent.png';
import { mapGetters } from 'vuex';

export default {
  methods: {
    home() {
      this.$store.commit("SET_CENTER", null);
      this.$store.commit("SET_CENTER", this.GET_MARKER_LAT_LNG);
      this.$store.commit("SET_ZOOM", 16);
    },
    go(item) {
      this.$store.commit("SET_CENTER", null);
      this.$store.commit("SET_CENTER", item);
      this.$store.commit("SET_ZOOM", 16);
    },
    updateZoom() {
      this.$store.commit("SET_ZOOM", this.$refs.map.mapObject.getZoom());
    },
    moveMarker(event) {
      const { lat, lng } = event.latlng;
      console.log({ lat, lng });
      this.$store.commit("SET_MARKER_LAT_LNG", [lat, lng]);
    },
    getTooltipContent(item) {
      const matchingBranch = this.GET_FILTERED_BRANCH.find((branch) => {
        return branch.latitude === item[0] && branch.longitude === item[1];
      });
      return `
                <div>
                <center><strong style='color:#eb8f34;'>${matchingBranch.name}</strong></center><br>
                <i class="mdi mdi-map-marker" style="color:#eb8f34;"></i>${matchingBranch.address}<br>
                <i class="mdi mdi-phone" style='color:red;'></i>${matchingBranch.contact}
                </div>
            `;
    },
  },

  data() {
        return {
          popupOpen: true,
            circleRadius: 10000,
            zoom: 7,
            center: this.GET_CENTER,
            customIcon: L.icon({
                iconUrl: markerIcon,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
            }),
            customIcon2: L.icon({
                iconUrl: markerIcon2,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
            }),
            customIcon3: L.icon({
                iconUrl: markerIcon3,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
            }),
        };
    },

  mounted() {
    this.center = this.GET_CENTER;
    this.zoom = this.GET_ZOOM;
    // console.log(this.GET_MULTIPLE_MARKER_LAT_LNG)
  },

  computed: {
    ...mapGetters([
      "GET_USER_ROLE",
      "GET_MARKER_LAT_LNG",
      "GET_MULTIPLE_MARKER_LAT_LNG",
      "GET_ZOOM",
      "GET_CENTER",
      "GET_FILTERED_BRANCH",
      "GET_CLICKED_MARKER",
    ]),
  },

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LTooltip,
    LPopup
  },

  watch: {
    GET_CENTER: {
      handler(newVal, oldVal) {
        this.center = this.GET_CENTER;
        this.zoom = this.GET_ZOOM;
        this.$refs.map.mapObject.setView(this.center, this.zoom);
      },
    },
    GET_CLICKED_MARKER: {
      handler(val) {
        if(val != null){
          this.$refs.markers[val].mapObject.fire('click');
          this.$store.commit('SET_CLICKED_MARKER',null)
        }
      },
    },
  },
};
</script>

<style>
.map-container {
  position: relative;
  z-index: 1;
}
.l-tooltip-content {
  white-space: pre-line; /* Allow line breaks using \n */
}
</style>
