import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ConcernTable from '@/views/Tables/ConcernTable.vue';
import AdminBranchTable from '@/views/Tables/AdminBranchTable.vue';
import UserBranchTable from '@/views/Tables/UserBranchTable.vue';
import TicketStatus from '@/views/Components/TicketStatus.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/UserBranchTable',
    name: 'UserBranchTable',
    component: UserBranchTable
  },
  {
    path: '/AdminBranchTable',
    name: 'AdminBranchTable',
    component: AdminBranchTable
  },
  {
    path: '/concerntables',
    name: 'concerntables',
    component: ConcernTable,
  },
  {
    path: '/ticketstatus',
    name: 'ticketstatus',
    component: TicketStatus,
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
