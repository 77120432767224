import user from '@/api/modules/users/index'

export default {
    state: {
    
    },
    
    getters: {

    },

    mutations: {

    },

    actions: {
        saveUser({commit}, payload){
            return new Promise((resolve,reject)=>{
            user.saveUser(payload.params,payload.config).then((response)=>{
                resolve(response.data)
              }).catch((error)=>{
                  reject(error)
              });
            })
          },
        readUserDataTable({commit}, payload){
            return new Promise((resolve,reject)=>{
            user.readUserDataTable(payload).then((response)=>{
                resolve(response.data)
              }).catch((error)=>{
                  reject(error)
              });
            })
          },
          deleteUserById({commit}, payload){
            return new Promise((resolve,reject)=>{
            user.deleteUserById(payload).then((response)=>{
                resolve(response.data)
              }).catch((error)=>{
                  reject(error)
              });
            })
          },
    }
}