import ticket_pics from '@/api/modules/ticket_pics/index'

export default {
    state: {
    
    },
    
    getters: {

    },

    mutations: {

    },

    actions: {
        getTicketPicturesById({commit}, payload){
            return new Promise((resolve,reject)=>{
            ticket_pics.getTicketPicturesById(payload).then((response)=>{
                resolve(response.data)
              }).catch((error)=>{
                  reject(error)
              });
            })
          },
    }
}