<template>
  <div class="concerns_table">
    <v-card>
      <v-card-title>
        <h4>Concerns</h4>
      </v-card-title>
      <v-divider></v-divider>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="search"
        label="Search"
        class="mr-5 ml-5"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="concerns"
        :server-items-length="serverItemsLength"
        :options.sync="options"
      >
        <template v-slot:[`item.concerns`]="{ item }">
          <div class="text">
            {{ item.concerns | truncate(10) }}
          </div>
        </template>

        <template slot="item.actions" slot-scope="{ item }">
          <v-btn text icon color="warning" @click="viewRow(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn text icon color="error" @click="deleteRow(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="concern_dialog" max-width="750px" max-height="150px">
      <v-card>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-carousel v-if="pictures.length > 0">
          <v-carousel-item v-for="(item, index) in pictures" :key="index">
            <v-img contain :src="item" eager class="image" />
          </v-carousel-item>
        </v-carousel>
        <v-card-title class="d-flex justify-center">
          <div class="txtFont">
            <h1 align="center">{{ currentConcern.name }}</h1>
            <h4 align="center">{{ currentConcern.address }}</h4>
            <h5 align="center">
              {{ currentConcern.contact }} | {{ currentConcern.email }}
            </h5>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-textarea
          v-model="currentConcern.concerns"
          class="ml-3 mr-3"
          readonly
          label="Sender's Concern"
        ></v-textarea>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictures: [],
      concerns: [],
      options: {},
      isExecuting: false,
      concern_dialog: false,
      disable: false,
      options: null,
      search: "",
      actions: "",
      total: 0,
      serverItemsLength: 10,
      itemsPerPageOptions: [10, 20, 30, 40, 50, -1],
      currentConcern: {
        name: null,
        contact: null,
        email: null,
        address: null,
        concerns: null,
      },
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Contact #", value: "contact", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Address", value: "address", sortable: false },
        { text: "Concerns", value: "concerns", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  mounted() {
    this.readUserDataTable();
  },

  methods: {
    viewRow(item) {
      this.$store
        .dispatch("getTicketPicturesById", { user_id: item.id })
        .then((response) => {
          this.pictures = response;
          this.currentConcern.name = item.name;
          this.currentConcern.contact = item.contact;
          this.currentConcern.email = item.email;
          this.currentConcern.address = item.address;
          this.currentConcern.concerns = item.concerns;
          console.log(this.currentConcern);
          this.concern_dialog = true;
        });
    },

    deleteRow(item) {
      this.$swal
        .fire({
          icon: "question",
          title: "Are you sure?",
          text: "Do you want to delete this branch?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isConfirmed) {
            // User confirmed, proceed with deletion
            this.$store
              .dispatch("deleteUserById", { user_id: item.id })
              .then(() => {
                this.readUserDataTable();
                this.$swal.fire({
                  icon: "success",
                  title: "Deleted!",
                  text: "The user has been deleted.",
                });
              })
              .catch((error) => {
                console.error("An error occurred while deleting:", error);
                this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "An error occurred while deleting the user.",
                });
              });
          } else {
            // User cancelled, do nothing or show a message
          }
        });
    },

    async readUserDataTable() {
      if (this.isExecuting) {
        return; // Do not execute if the function is already running
      }
      try {
        this.isExecuting = true;
        await new Promise((resolve) => setTimeout(resolve, 500)); // Add a delay of 1.5 seconds
        const response = await this.$store
          .dispatch("readUserDataTable", this.options)
          .then((response) => {
            this.concerns = response.data;
            this.serverItemsLength = response.total;
            this.isExecuting = false;
          });
      } catch (error) {
        console.error("An error occurred:", error);
        this.isExecuting = false;
      }
    },
  },

  watch: {
    search: {
      handler(val) {
        console.log(this.options);
        this.options = {
          search: val,
        };

        this.readUserDataTable();
      },
    },
    options: {
      handler(val) {
        if (val.itemsPerPage < 0) {
          this.options.itemsPerPage = this.serverItemsLength;
        }
        this.readUserDataTable();
      },
    },
  },
};
</script>

<style>
.swal2-popup {
  font-family: "Montserrat", sans-serif;
}
.text-h2 {
  font-size: 2rem;
}

/* Optional: Set a maximum height for the textarea */
.v-textarea textarea {
  max-height: 300px; /* Adjust this value as needed */
  overflow-y: auto; /* Add a scrollbar when the content exceeds the max-height */
}

@font-face {
  font-family: "Garet-Book";
  src: url("~@/assets/fonts/Garet-Book.otf") format("opentype");
}

.txtFont {
  font-family: "Garet-Book", sans-serif;
}
</style>
