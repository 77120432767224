import api from '@/api/index'

export default {
    saveBranch(params){
        return api.post('api/createBranch',params);
    },
    updateBranchById(params){
        return api.put('api/updateBranchById',params);
    },
    readAllBranchs(params){
        return api.get('api/readAllBranchs');
    },
    readBranchsDataTable(params){
        return api.get(`api/readBranchsDataTable`,{params});
    },
    readBranchsDataTableNoPaginator(params){
        return api.get(`api/readBranchsDataTableNoPaginator`,{params});
    },
    deleteBranchById(params){
        return api.delete(`api/deleteBranchById/${params}`);
    },
}