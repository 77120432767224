<template>
  <div class="pattern-background">
    <Header
      :hideButton="true"
      :hideUserBranchTableButton="true"
      :hideTicketStatusSearchButton="true"
    />
    <v-main>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="8">
            <v-card class="elevation-10 mt-10 mb-15">
              <v-card-title
                class="evogriafont"
                style="background-color: rgb(242, 159, 19)"
              >
                <h1>Ticket Status</h1>
                <v-divider class="black"></v-divider>
                <v-icon>mdi-ticket-account</v-icon>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-stepper v-model="test" alt-labels class="mb-15 mt-15">
                  <v-stepper-header>
                    <v-stepper-step
                      :color="test == 1 ? 'warning' : 'grey'"
                      step="1"
                    >
                      <div class="text-center">Enter Ticket Number</div>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :color="test == 2 ? 'warning' : 'grey'"
                      step="2"
                    >
                      Ticket Status
                      <small>Processes</small>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :color="test == 3 ? 'warning' : 'grey'"
                      step="3"
                      >More</v-stepper-step
                    >
                  </v-stepper-header>
                </v-stepper>
                <!-- Elements to Show if test == 1 -->
                <v-text-field
                  v-if="test == 1"
                  @keyup.enter="findCustomerTix"
                  outlined
                  v-model="storeTicketNum"
                  dense
                  color="black"
                  label="Ticket Number"
                  class="evogriafont"
                >
                </v-text-field>
                <label v-if="test == 1"
                  >Your ticket number should look like:
                  C-TIX-2023-01-0001</label
                >
                <!-- Elements to Show if test == 2 -->
                <template v-if="test == 2">
                  <v-container class="grey lighten-5">
                    <v-row no-gutters>
                      <v-col order="first" cols="12" md="4" sm="6">
                        Name
                        <v-card
                          class="pa-2"
                          outlined
                          tile
                        >
                          {{ ticket.ticket_name }}
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        Subject of Concern
                        <v-card
                          class="pa-2"
                          outlined
                          tile
                        >
                          {{ ticket.subject_title }}
                        </v-card>
                      </v-col>
                      <v-col order="last" cols="12" md="4" sm="6">
                        Ticket Status
                        <v-card
                          class="pa-2"
                          outlined
                          tile
                        >
                          {{ ticket.status_name }}
                        </v-card>
                      </v-col>
                    </v-row>
                    <!-- Customer Chat with Support -->
                    <v-row no-gutters>
                      <v-col class="mt-2">
                        Chat with our Agents
                          <v-card class="overflow-hidden mt-2" persistent outlined dense>
                            <v-container fluid class="d-flex justify-space-between" style="background-color: #f69f1a;">
                                <div>
                                  <h2> 
                                    <v-icon>mdi-forum-outline</v-icon> 
                                    {{ GET_AGENT_ID }}
                                  </h2>
                                </div>
                            </v-container>
                            <v-card class="overflow-card" height="500px" max-height="500px" dense outlined id="card_id" ref="card">
                                <v-card-text class="overflow-content" max-height="500px" ref="content">
                                    <!-- <v-row class="d-flex justify-content-center pt-5 pb-5" v-for="message in messages" :key="message.id">
                                        <v-col :class="message.from == Get_UserInfo.id ? 'text-right' : 'text-left'">
                                            <v-btn v-if="message.from == Get_UserInfo.id" icon @click="deleteMessage(message)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                            <v-chip medium class="white--text" rounded text-color="white"
                                                :color="message.from == Get_UserInfo.id ? 'primary' : 'orange'">
                                                {{ message.message }}
                                            </v-chip>
                                            <v-btn v-if="message.from != Get_UserInfo.id" icon @click="deleteMessage(message)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                            <br />
                                            <span style="font-size: 12px;">
                                                {{ message.created_at | formatDate }}
                                            </span> 
                                        </v-col>
                                    </v-row> -->
                                </v-card-text>
                            </v-card>
                            <v-card-actions>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field class="pl-1 pt-2 pr-2" label="Send a message..." solo dense outlined>
                                            <!-- {{ chats.message }} -->
                                            <template slot="append">
                                                <v-btn text icon>
                                                  <!-- @click="submit" -->
                                                    <v-icon>mdi-send</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                          </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>

                <!-- Elements to Show if test == 3 -->
                <template v-if="test == 3">
                  <v-container class="grey lighten-5">
                    <v-row>
                      <v-col cols="12" md="4" sm="6">
                        <v-card class="mx-auto" max-width="344">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                            height="200px"
                          ></v-img>
                          <v-card-title>Submit A Ticket?</v-card-title>
                          <v-card-subtitle
                            >We want to hear from you!</v-card-subtitle
                          >
                          <v-card-actions>
                            <v-btn
                              color="orange lighten-2"
                              @click="submitNewTicket"
                              text
                              >Submit</v-btn
                            >
                            <v-spacer></v-spacer>
                            <v-btn icon>
                              <v-icon></v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="4" sm="6">
                        <v-card class="mx-auto" max-width="344">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                            height="200px"
                          ></v-img>
                          <v-card-title
                            >See Service Centers Nearby</v-card-title
                          >
                          <v-card-subtitle
                            >INGCO PH Service Centers
                            Nationwide</v-card-subtitle
                          >
                          <v-card-actions>
                            <v-btn
                              color="orange lighten-2"
                              @click="seeServiceCenters"
                              text
                              >Take Me</v-btn
                            >
                            <v-spacer></v-spacer>
                            <v-btn icon>
                              <v-icon></v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="4" sm="6">
                        <v-card class="mx-auto" max-width="344">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                            height="200px"
                          ></v-img>
                          <v-card-title
                            >Check Another Ticket Status</v-card-title
                          >
                          <v-card-subtitle
                            >Check your other submitted concerns and
                            tickets</v-card-subtitle
                          >
                          <v-card-actions>
                            <v-btn
                              color="orange lighten-2"
                              @click="checkAnotherTicket"
                              text
                              >Click Me</v-btn
                            >
                            <v-spacer></v-spacer>
                            <v-btn icon>
                              <v-icon></v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions style="background-color: rgb(242, 159, 19)">
                <v-spacer></v-spacer>
                <!-- Tix = Tickets
                  This Button is used for Fetching User's Ticket Number specifically on Customer -->
                <v-btn
                  class="copy-button orange lighten-2 pa-6"
                  tile
                  block
                  v-if="test == 1"
                  @click="findCustomerTix"
                >
                  Find My Ticket
                  <v-icon class="ml-3">mdi-file-search</v-icon>
                </v-btn>
                <!-- Step 2 for Fetching User's Ticket Status -->
                <v-btn
                  class="copy-button orange lighten-2 pa-6"
                  tile
                  block
                  v-if="test == 2"
                  @click="toThirdStep()"
                >
                  Next
                  <v-icon class="ml-3">mdi-arrow-right-bold</v-icon>
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/views/Headers.vue";
import Footer from "@/views/Footers.vue";
import Swal from "sweetalert2";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      test: 1,
      storeTicketNum: null,
      displayTicketNum: null,
      agent: '',
      ticket: {
        ticket_name: null,
        subject_title: null,
        status_name: null,
      },
    };
  },

  mounted() {
    this.$store.dispatch('getAgentName');
  },

  methods: {
    findCustomerTix() {
      const data = {
        ticket_num: this.storeTicketNum,
      };
      this.$store.dispatch("searchTicketNumber", data)
        .then((response) => {
          if (response.data == "") {
            Swal.fire({
              text: "Ticket number not found.",
              icon: "error",
            });
            return;
          }
          this.ticket.ticket_name = response.data.ticket_name;
          this.ticket.subject_title = response.data.subject_title;
          this.ticket.status_name = response.data.status_name;
          // console.log(this.displayTicketNum);
          this.test = 2;
        })
        .catch((error) => {
          // console.error("An error occurred:", error);

          if (error.message === "Ticket number is required.") {
            Swal.fire({
              text: "Ticket number is required.",
              icon: "error", 
            });
          } else if (error.message === "Invalid ticket number format.") {
            Swal.fire({
              text: "Invalid ticket number format.",
              icon: "error",
            });
          } else {
            // Handle other errors
          }
        });
    },

    toThirdStep() {
      this.test = 3;
    },
    toFirstStep() {
      this.test = 1;
    },
    // Directory Step 3 Buttons
    submitNewTicket() {
      const url = "/";
      window.open(url, "_blank");
    },
    seeServiceCenters() {
      const url = "/UserBranchTable";
      window.open(url, "_blank");
    },
    checkAnotherTicket() {
      this.storeTicketNum = '';
      this.test = 1;
    },
  },
  computed: {
    ...mapGetters([
      "GET_TICKET_NUM",
      "GET_AGENT_ID",
    ]),
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Evogria";
  src: url("~@/assets/fonts/Evogria.otf") format("opentype");
}

.evogriafont {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

@media screen and (max-width: 600px) {
  .evogriafont {
    font-size: 18px; /* Adjust the font size for smaller screens */
  }
}
</style>
