<template>
    <div>
      <mapComp/>
      <v-autocomplete 
            v-if="GET_USER_ROLE == 'user'"
            v-model="selectedBranch"
            class="d-flex justify-center mr-7 ml-7 mt-7" outlined
            :items="filteredBranch"
            item-text="name"
            item-value="index"
            placeholder="Select Branch"
      ></v-autocomplete>
    </div>  
  </template>
  
  <script>
   import mapComp from '@/views/Components/mapComp.vue';
   import { mapGetters } from 'vuex';
  
   export default{
      
      data(){
        return{
          selectedBranch: null,
          branches: [],
          filteredBranch: [],
          markerLatLng: []
        }
      },
  
      components: {
        mapComp
      },
  
      mounted(){      
        this.$store.commit('SET_USER_ROLE','user')
        this.fetchBranch()
      },
  
      methods: {
        fetchBranch(){
          this.$store.dispatch('readAllBranchs')
          .then(response => {
            this.branches = response.map((item, index) => ({ ...item, index }));
            
            const arr = []
            this.branches.forEach(item => {
              // console.log(item.latitude)
              arr.push([item.latitude, item.longitude ])
            });
            this.$store.commit('SET_MULTIPLE_MARKER_LAT_LNG',arr);
            // console.log(this.GET_MULTIPLE_MARKER_LAT_LNG)
  
            if ('geolocation' in navigator) {
              navigator.geolocation.getCurrentPosition(
              position => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                // this.markerLatLng = [latitude, longitude];
                this.markerLatLng[0] = latitude;
                this.markerLatLng[1] = longitude;
                // console.log('Latitude:', latitude);
                // console.log('Longitude:', longitude);
                this.filterBranchOptions();
                this.$store.commit('SET_FILTERED_BRANCH',this.filteredBranch);
              },
              error => {
                console.error('Error occurred while obtaining location:', error);
              },
              {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
              }
            );
          } 
          else {
            console.log('Geolocation is not supported by this browser.');
          }
          // console.log(this.filteredBranch)
          // console.log(this.branches)
          })
        },

        filterBranchOptions() {
          if (!this.markerLatLng) {
            return;
          }
          const userLatLng = this.markerLatLng;
          const sortedOptions = [...this.branches];
          sortedOptions.sort((a, b) => {
            const latA = Number(a.latitude);
            const lonA = Number(a.longitude);
            const latB = Number(b.latitude);
            const lonB = Number(b.longitude);
            if (isNaN(latA) || isNaN(lonA) || isNaN(latB) || isNaN(lonB)) {
              // Skip invalid latitude/longitude values
              console.log('Invalid latitude/longitude:', a.latitude, a.longitude, b.latitude, b.longitude);
              return 0;
            }
            const distanceA = this.calculateDistance(userLatLng, [latA, lonA]);
            const distanceB = this.calculateDistance(userLatLng, [latB, lonB]);
            return distanceA - distanceB;
          });
  
          this.filteredBranch = sortedOptions.map((item, index) => ({ ...item, index }));
        },
  
        calculateDistance(pointA, pointB) {
          // Implement your distance calculation logic here
          // This example uses the Haversine formula
          const lat1 = pointA[0];
          const lon1 = pointA[1];
          const lat2 = pointB[0];
          const lon2 = pointB[1];
          const R = 6371; // Radius of the Earth in kilometers
          const dLat = this.toRad(lat2 - lat1);
          const dLon = this.toRad(lon2 - lon1);
          const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.toRad(lat1)) *
              Math.cos(this.toRad(lat2)) *
              Math.sin(dLon / 2) *
              Math.sin(dLon / 2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          const distance = R * c;
          return distance;
        },
  
        toRad(value) {
          return value * (Math.PI / 180);
        },
  
      },
  
      computed: {
        ...mapGetters([
            'GET_USER_ROLE',
            'GET_MARKER_LAT_LNG',
            'GET_MULTIPLE_MARKER_LAT_LNG',
            'GET_ZOOM',
            'GET_CENTER',
        ]),
      },
  
      watch: {
        selectedBranch: {
          handler(newVal) {
              
            let currentBranch = this.filteredBranch[newVal];
            let arr = [currentBranch.latitude, currentBranch.longitude];
            this.$store.commit('SET_CENTER', arr);
            this.$store.commit('SET_ZOOM', 13);
            // console.log(this.GET_CENTER);
            // this.$store.commit('SET_MARKER_LAT_LNG',arr)
            // console.log(this.GET_MARKER_LAT_LNG);
          },
          deep: true // Set the deep option to true for deep watching
        }
      }
  
  
  
   }
  
  </script>