import branchs from '@/api/modules/branchs/index'

export default {
  state: {
    filteredBranch: [],
  },

  getters: {
    GET_FILTERED_BRANCH: (state) => state.filteredBranch,
  },

  mutations: {
    SET_FILTERED_BRANCH: (state, data) => { state.filteredBranch = data },
  },

  actions: {
    saveBranch({ commit }, payload) {
      return new Promise((resolve, reject) => {
        branchs.saveBranch(payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    updateBranchById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        branchs.updateBranchById(payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    readAllBranchs({ commit }) {
      return new Promise((resolve, reject) => {
        branchs.readAllBranchs().then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    readBranchsDataTable({ commit }, payload) {
      return new Promise((resolve, reject) => {
        branchs.readBranchsDataTable(payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    readBranchsDataTableNoPaginator({ commit }, payload) {
      return new Promise((resolve, reject) => {
        branchs.readBranchsDataTableNoPaginator(payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    deleteBranchById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        branchs.deleteBranchById(payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
  }
}