<template>
  <div class="pattern-background">
    <Headers
      :hideButton="true"
      :showUserBranchTableButton="true"
      :showMapDialog="false"
      @open-admin-dialog="showAdminDialog = true"
    />
    <v-container class="justify-center">
      <v-row class="mt-16 mb-15">
        <!-- First Column - Data Table -->
        <v-col cols="12" md="8">
          <Concern />
        </v-col>

        <!-- Second Column - Map -->
        <v-col cols="12" md="4">
          <!-- Your map component goes here -->
          <mapComp class="mb-5" />
          <!-- <mapWithDropdown/> -->

          <v-card>
            <!-- Search Bar -->
            <v-card-title>
              <h4>INGCO Branches</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-row justify="end">
              <v-col
                class="searchbar d-flex justify-end align-center"
                cols="12"
              >
                <v-text-field
                  v-model="search"
                  label="Search"
                  single-line
                  dense
                  hide-details
                  class="ml-3 mr-3"
                ></v-text-field>
                <v-btn @click="searchInput" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <!-- Data Table -->
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="branches"
                    :server-items-length="serverItemsLength"
                    :options.sync="options"
                  >

                  <template v-slot:item="{ item }">
                    <tr @click="viewRow(item)" style="cursor: pointer;">
                      <td>
                        <strong>{{ item.name }}</strong><br>
                      </td>
                      <td>
                        {{ item.address }}
                      </td>
                      <td>
                        {{ item.contact }}
                      </td>
                      <td>
                          <v-btn
                              @click.stop="updateRow(item)"
                              text
                              icon
                              color="primary"
                              small
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          <v-btn
                              @click.stop="deleteRow(item)"
                              text
                              icon
                              color="error"
                              small
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                      </td>
                    </tr>
                  </template>

                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="showAdminDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <div class="pattern-background">
        <v-toolbar color="rgb(242 159 19)">
          <v-toolbar-title class="txtGlobal">Add Store Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" @click="btnCloseDialog">
            <v-icon class="">mdi-exit-to-app</v-icon>
          </v-btn>
        </v-toolbar>
        <v-subheader class="white--text txtGlobaldescription">
          Add a Store for Customer Service Location Finder
        </v-subheader>

        <div class="search-container">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search location..."
            outlined
            dense
            solo
          >
          </v-text-field>
          <v-card>
            <ul v-if="suggestions.length" solo class="suggestions">
              <li
                v-for="suggestion in suggestions"
                :key="suggestion.id"
                @click="selectSuggestion(suggestion)"
              >
                {{ suggestion.label }}
              </li>
            </ul>
          </v-card>
          <!-- <v-btn @click="fetchSuggestions" color="primary" class="ml-2">Search</v-btn> -->
        </div>
        <v-container>
          <v-card>
            <mapComp class="mb-10" />
            <v-form ref="myForm" @submit.prevent>
              <v-container>
                <v-row class="ma-3">
                  <v-layout justify-center>
                    <div>
                      <div class="txtGlobal">Fill Up Branch Informations</div>
                    </div>
                  </v-layout>
                </v-row>
                <!-- First row -->
                <v-row class="ma-3">
                  <v-text-field
                    v-model="form.name"
                    label="Enter Branch Name"
                    outlined
                    dense
                    class="txtGlobal"
                    autocomplete="off"
                    :rules="rules.required"
                    ref="nameField"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.address"
                    label="Enter Branch Address"
                    outlined
                    class="mr-5 ml-5 txtGlobal"
                    dense
                    autocomplete="off"
                    :rules="rules.required"
                    ref="addressField"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.contact"
                    label="Enter Branch Contact"
                    outlined
                    dense
                    class="txtGlobal"
                    autocomplete="off"
                    type="number"
                    :rules="rules.min"
                    @keydown="limitCharacterCount"
                  ></v-text-field>
                </v-row>

                <!-- Second row -->
                <v-row class="ma-3">
                  <v-spacer></v-spacer>
                  <!-- Add a spacer to push the button to the center -->
                  <v-btn @click="submit" class="orange lighten-2"
                    >Save Location
                    <v-icon class="ml-3">mdi-map-marker-plus</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-container>
      </div>
    </v-dialog>
    <Footers />
  </div>
</template>

<script>
import mapWithDropdown from "@/views/Components/mapWithDropdown.vue";
import Headers from "@/views/Headers.vue";
import Footers from "@/views/Footers.vue";
import mapComp from "@/views/Components/mapComp.vue";
import Concern from "@/views/Tables/ConcernTable.vue";
import { mapGetters } from "vuex";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

export default {
  components: {
    mapComp,
    mapWithDropdown,
    Footers,
    Headers,
    Concern,
  },

  data() {
    return {
      watcherEnabled : true,
      snackbar: true,
      showAdminDialog: false,
      isExecuting: false,
      options: null,
      search: "",
      serverItemsLength: 10,
      branches: [],
      rules: {
        required: [(v) => !!v || "Field is required"],
        min: [(v) => v.length >= 11 || "Min 11 characters"],
      },
      form: {
        name: null,
        address: null,
        contact: "",
        latitude: null,
        longitude: null,
      },
      updateForm: {
        id: null,
        name: null,
        address: null,
        contact: "",
        latitude: null,
        longitude: null,
      },
      searchQuery: "",
      suggestions: [],

      headers: [
        { text: "name", value: "name", sortable: false },
        { text: "address", value: "address", sortable: false },
        { text: "contact", value: "contact", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "GET_USER_ROLE",
      "GET_MARKER_LAT_LNG",
      "GET_MULTIPLE_MARKER_LAT_LNG",
      "GET_ZOOM",
      "GET_CENTER",
      "GET_FILTERED_BRANCH",
    ]),
  },

  mounted() {
    this.$store.commit("SET_USER_ROLE", "admin");
    // this.form.latitude = this.GET_MARKER_LAT_LNG[0]
    // this.form.longitude = this.GET_MARKER_LAT_LNG[1]
    // console.log(this.GET_MARKER_LAT_LNG[0])
    // console.log(this.GET_MARKER_LAT_LNG[1])
    this.readBranchsDataTable();
  },

  methods: {
    updateRow(item){
      console.log(item)
    },
    btnCloseDialog() {
      this.showAdminDialog = false;
    },

    async readUserDataTable() {
      if (this.isExecuting) {
        return; // Do not execute if the function is already running
      }
      try {
        this.isExecuting = true;
        await new Promise((resolve) => setTimeout(resolve, 500)); // Add a delay of 1.5 seconds
        const response = await this.$store
          .dispatch("readUserDataTable", this.options)
          .then((response) => {
            this.concerns = response.data;
            this.serverItemsLength = response.total;
            this.isExecuting = false;
          });
      } catch (error) {
        console.error("An error occurred:", error);
        this.isExecuting = false;
      }
    },

    limitCharacterCount(event) {
      if (this.form.contact && this.form.contact.length >= 11) {
        if (
          !(
            event.key === "Backspace" ||
            event.key === "Delete" ||
            event.key.includes("Arrow")
          )
        )
          event.preventDefault();
      }
    },

    searchInput() {
      this.options = {
        search: this.search, // Add a new attribute
      };
      this.readBranchsDataTable();
    },
    removeSelectedBranch() {
      this.updateForm.id = null;
      this.updateForm.name = null;
      this.updateForm.latitude = null;
      this.updateForm.longitude = null;
    },
    viewRow(item) {
      let arr = [item.latitude, item.longitude];
      this.$store.commit("SET_CENTER", arr);
      this.$store.commit("SET_ZOOM", 16);
      this.form.latitude = item.latitude;
      this.form.longitude = item.longitude;
      this.form.name = "";
      this.form.address = "";
      this.form.contact = "";

      this.updateForm.latitude = item.latitude;
      this.updateForm.longitude = item.longitude;
      this.updateForm.name = item.name;
      this.updateForm.address = item.address;
      this.updateForm.contact = item.contact;
      this.updateForm.id = item.id;
    },
    deleteRow(item) {
      this.$swal
        .fire({
          icon: "question",
          title: "Are you sure?",
          text: "Do you want to delete this branch?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (item.name == this.updateForm.name) {
              this.updateForm.id = null;
              this.updateForm.name = null;
              this.updateForm.latitude = null;
              this.updateForm.longitude = null;
            }
            this.$store
              .dispatch("deleteBranchById", item.id)
              .then((response) => {
                this.readBranchsDataTable();
                this.$swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "Operation completed successfully.",
                });
              })
              .catch((error) => {
                console.error(error);
                this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "An error occurred while saving the branch.",
                });
              });
          }
        });
    },

    selectSuggestion(suggestion) {
      this.watcherEnabled = false
      let arr = [suggestion.latitude, suggestion.longitude];
      this.$store.commit("SET_CENTER", arr);
      this.$store.commit("SET_MARKER_LAT_LNG", arr);
      this.$store.commit("SET_ZOOM", 16);
      // this.$store.commit('SET_ZOOM', 13);
      this.suggestions = [];
      this.form.address = suggestion.label;
      this.searchQuery = suggestion.label;
      this.enableWatcherAfterDelay()
    },

    enableWatcherAfterDelay() {
      setTimeout(() => {
        this.watcherEnabled = true;
      }, 1000);
    },

    async fetchSuggestions() {
      if (this.isExecuting) {
        return; // Do not execute if the function is already running
      }
      try {
        this.isExecuting = true;
        await new Promise((resolve) => setTimeout(resolve, 500)); // Add a delay of 1.5 seconds

        const provider = new OpenStreetMapProvider();
        provider
          .search({ query: this.searchQuery })
          .then((results) => {
            this.suggestions = results.map((result, index) => ({
              id: index,
              label: result.label,
              // Latitude is the Y axis, longitude is the X axis.
              longitude: result.x,
              latitude: result.y,
            }));
          })
          .catch((error) => {
            console.error("Error searching location:", error);
          });

        this.isExecuting = false;
      } catch (error) {
        console.error("An error occurred:", error);
        this.isExecuting = false;
      }
    },

    update(item) {
      alert("update");
    },

    async readBranchsDataTable() {
      if (this.isExecuting) {
        return; // Do not execute if the function is already running
      }
      try {
        this.isExecuting = true;
        await new Promise((resolve) => setTimeout(resolve, 500)); // Add a delay of 1.5 seconds
        await this.$store
          .dispatch("readBranchsDataTable", this.options)
          .then((response) => {
            this.branches = response.data;
            this.serverItemsLength = response.total;
            if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const latitude = position.coords.latitude;
                  const longitude = position.coords.longitude;
                  const markerLatLng = [latitude, longitude];
                  this.$store.commit("SET_MARKER_LAT_LNG", markerLatLng);
                  this.$store.commit("SET_CENTER", markerLatLng);

                  // this.filterBranchOptions();
                },
                (error) => {
                  console.error(
                    "Error occurred while obtaining location:",
                    error
                  );
                },
                {
                  enableHighAccuracy: true,
                  timeout: 5000,
                  maximumAge: 0,
                }
              );
            } else {
              console.log("Geolocation is not supported by this browser.");
            }

            const arr = [];
            this.branches.forEach((item) => {
              arr.push([item.latitude, item.longitude]);
            });
            this.$store.commit("SET_MULTIPLE_MARKER_LAT_LNG", arr);
          });

        this.isExecuting = false;
      } catch (error) {
        console.error("An error occurred:", error);
        this.isExecuting = false;
      }
    },

    filterBranchOptions() {
      if (!this.GET_MARKER_LAT_LNG) {
        return;
      }
      const userLatLng = this.GET_MARKER_LAT_LNG;
      const sortedOptions = [...this.branches];
      sortedOptions.sort((a, b) => {
        const latA = Number(a.latitude);
        const lonA = Number(a.longitude);
        const latB = Number(b.latitude);
        const lonB = Number(b.longitude);
        if (isNaN(latA) || isNaN(lonA) || isNaN(latB) || isNaN(lonB)) {
          // Skip invalid latitude/longitude values
          console.log(
            "Invalid latitude/longitude:",
            a.latitude,
            a.longitude,
            b.latitude,
            b.longitude
          );
          return 0;
        }
        const distanceA = this.calculateDistance(userLatLng, [latA, lonA]);
        const distanceB = this.calculateDistance(userLatLng, [latB, lonB]);
        return distanceA - distanceB;
      });

      this.branches = sortedOptions.map((item, index) => ({ ...item, index }));
    },

    calculateDistance(pointA, pointB) {
      // This example uses the Haversine formula
      const lat1 = pointA[0];
      const lon1 = pointA[1];
      const lat2 = pointB[0];
      const lon2 = pointB[1];
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = this.toRad(lat2 - lat1);
      const dLon = this.toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRad(lat1)) *
          Math.cos(this.toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    },

    toRad(value) {
      return value * (Math.PI / 180);
    },

    submit() {
      if (this.$refs.myForm.validate()) {
        // console.log(this.updateForm)
        if (this.updateForm.name == null) {
          this.$swal
            .fire({
              icon: "question",
              title: "Are you sure?",
              text: "Do you want to save this branch?",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$store
                  .dispatch("saveBranch", this.form)
                  .then((response) => {
                    this.readBranchsDataTable();
                    this.$swal.fire({
                      icon: "success",
                      title: "Success!",
                      text: "Operation completed successfully.",
                    });
                    this.clear();
                  })
                  .catch((error) => {
                    console.error(error);
                    this.$swal.fire({
                      icon: "error",
                      title: "Error",
                      text: "An error occurred while saving the branch.",
                    });
                  });
              }
            });
        } else {
          this.$swal
            .fire({
              icon: "question",
              title: "Are you sure?",
              text: "Do you want to update this branch?",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.updateForm.name = this.form.name;
                this.updateForm.address = this.form.address;
                this.updateForm.contact = this.form.contact;
                this.updateForm.latitude = this.form.latitude;
                this.updateForm.longitude = this.form.longitude;
                this.$store
                  .dispatch("updateBranchById", this.updateForm)
                  .then((response) => {
                    this.readBranchsDataTable();
                    this.$swal.fire({
                      icon: "success",
                      title: "Success!",
                      text: "Operation completed successfully.",
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                    this.$swal.fire({
                      icon: "error",
                      title: "Error",
                      text: "An error occurred while updating the branch.",
                    });
                  });
              }
            });
        }
      }
    },
    clear() {
      this.$refs.myForm.resetValidation();
      for (let key in this.form) {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = "";
        }
      }
    },
  },

  watch: {
    options: {
      handler(val) {
        if (val.itemsPerPage < 0) {
          // this.options.itemsPerPage = 20
        }
        // console.log( this.options.itemsPerPage)
        // console.log( this.serverItemsLength)
        this.readBranchsDataTable();
      },
      deep: true,
    },

    GET_MARKER_LAT_LNG: {
      handler(val) {
        this.form.latitude = val[0];
        this.form.longitude = val[1];
      },
    },

    "form.latitude": {
      handler(val) {
        this.$store.commit("SET_MARKER_LAT_LNG", [
          this.form.latitude,
          this.form.longitude,
        ]);
      },
    },
    "form.longitude": {
      handler(val) {
        this.$store.commit("SET_MARKER_LAT_LNG", [
          this.form.latitude,
          this.form.longitude,
        ]);
      },
    },

    search: {
      handler(val) {
        this.options = {
          search: this.search, // Add a new attribute
        };
        this.readBranchsDataTable();
      },
    },

    searchQuery: {
      handler(val) {
        if(this.watcherEnabled){
          this.fetchSuggestions();
        }
      },
    },
  },
};
</script>

<style scoped>
#app {
  text-align: center;
  margin-top: 2rem;
}

.outlined-div {
  border: 2px solid #ccc;
  /* You can adjust the color and size of the border as needed */
  padding: 10px;
  /* Optional: Add some padding around the content */
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #ccc;
}

.suggestions li {
  padding: 8px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f5f5f5;
}

.txtGlobal {
  font-family: "Evogria";
}

.txtGlobaldescription {
  font-family: "Montserrat", sans-serif;
}
</style>
