import axios from "axios";

// const URL = "http://127.0.0.1:8000/";
const URL = "http://127.0.0.1:8001/";
// const URL = "http://192.168.60.162:8001/";
// const URL = "http://45.118.132.159:3306/";

const instance = axios.create({
    baseURL:URL,
    withCredentials:false
});

instance.interceptors.request.use(function (request) {
    request.headers["Content-Type"] = 'application/json,text/html'
    // request.headers["Content-Type"] = 'multipart/form-data'
    request.headers['Accept'] = 'Application/json'
    return request;
  }, function (error) {
    return Promise.reject(error);
  });

  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });  
export default instance;
