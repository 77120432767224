import Vue from 'vue'
import Vuex from 'vuex'
import users from '@/store/modules/users'
import concerns from '@/store/modules/concerns'
import branchs from '@/store/modules/branchs'
import map from '@/store/modules/map'
import ticket_pics from '@/store/modules/ticket_pics'
import subjects from '@/store/modules/subjectsdropdown'

Vue.use(Vuex)

export default new Vuex.Store({
  
  strict: false,
  modules: {
    users,
    branchs,
    concerns,
    map,
    ticket_pics,
    subjects
  }
})
